import { useReport } from './hooks/use-context';

export function Sieving() {
  const { body } = useReport();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'visible',
        marginTop: '0.5rem',
      }}
    >
      <h2
        style={{
          fontSize: '1.25rem',
          fontWeight: 'bold',
          lineHeight: '1.25',
          color: '#495163',
        }}
      >
        PENEIRAMENTO
      </h2>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '0.5rem',
          paddingTop: '0.5rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Cacau Colado</p>
          <div
            style={{
              display: 'grid',
              height: 'auto',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gap: '0.5rem',
              paddingTop: '0.5rem',
            }}
          >
            {body?.cocoa_images?.answers.map(
              ({ id, content }) =>
                content && (
                  <img
                    key={id}
                    style={{
                      height: 'auto',
                      width: '100%',
                    }}
                    src={content}
                    alt="cocoa_image"
                  />
                ),
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Pó</p>
          <div
            style={{
              display: 'grid',
              height: 'auto',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gap: '0.5rem',
              paddingTop: '0.5rem',
            }}
          >
            {body?.dust_images?.answers.map(
              ({ id, content }) =>
                content && (
                  <img
                    key={id}
                    style={{
                      height: 'auto',
                      width: '100%',
                    }}
                    src={content}
                    alt="dust_image"
                  />
                ),
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '0.5rem',
          overflow: 'visible',
        }}
      >
        <div
          style={{
            display: 'flex',
            height: 'auto',
            overflow: 'visible',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Pesagens</p>
          <div
            style={{
              display: 'flex',
              overflow: 'visible',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              height: 'auto',
              gap: '0.5rem',
              paddingTop: '0.5rem',
              maxWidth: '720px',
              flexWrap: 'wrap',
            }}
          >
            {body?.weighings_images?.answers.map(
              ({ id, content }) =>
                content && (
                  <div style={{ pageBreakInside: 'avoid' }}>
                    <img
                      key={id}
                      style={{
                        height: 'auto',
                        width: '100%',
                        maxWidth: '90px',
                      }}
                      src={content}
                      alt="weighings_image"
                    />
                  </div>
                ),
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'grid',
          height: '100%',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '0.5rem',
          paddingTop: '0.5rem',
        }}
      >
        <span
          style={{
            display: 'flex',
            height: '2.5rem',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Peso - Cacau Colado</p>
          <p
            style={{
              marginRight: '0.25rem',
              marginTop: '-0.75rem',
              textAlign: 'end',
              fontSize: '1.125rem',
              fontWeight: 500,
              color: '#3B82F6',
            }}
          >
            {body?.cocoa_weight?.answers[0]?.content || '-'}
          </p>
        </span>
        <span
          style={{
            display: 'flex',
            height: '2.5rem',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Peso - Pó</p>
          <p
            style={{
              marginRight: '0.25rem',
              marginTop: '-0.75rem',
              textAlign: 'end',
              fontSize: '1.125rem',
              fontWeight: 500,
              color: '#3B82F6',
            }}
          >
            {body?.dust_weight?.answers[0]?.content || '-'}
          </p>
        </span>
        <span
          style={{
            display: 'flex',
            height: '2.5rem',
            width: 'auto',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Peso - Bruto</p>
          <p
            style={{
              marginRight: '0.25rem',
              marginTop: '-0.75rem',
              textAlign: 'end',
              fontSize: '1.125rem',
              fontWeight: 500,
              color: '#3B82F6',
            }}
          >
            {body?.gross_weight?.answers[0]?.content || '-'}
          </p>
        </span>
        <span
          style={{
            display: 'flex',
            height: '2.5rem',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Peso - Líquido</p>
          <p
            style={{
              marginRight: '0.25rem',
              marginTop: '-0.75rem',
              textAlign: 'end',
              fontSize: '1.125rem',
              fontWeight: 500,
              color: '#3B82F6',
            }}
          >
            {body?.net_weight?.answers[0]?.content || '-'}
          </p>
        </span>
        <span
          style={{
            height: '100%',
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Lacres</p>
          <p
            style={{
              marginRight: '0.25rem',
              marginTop: '-0.75rem',
              textAlign: 'end',
              fontSize: '1.125rem',
              fontWeight: 500,
              color: '#3B82F6',
            }}
          >
            {body?.seals?.answers[0]?.content || '-'}
          </p>
        </span>
        <div
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Foto do Veículo</p>
          <div
            style={{
              display: 'grid',
              height: 'auto',
              width: '100%',
              gap: '0.5rem',
              paddingTop: '0.5rem',
            }}
          >
            {body?.vehicle_image?.answers.map(
              ({ id, content }) =>
                content && (
                  <img
                    key={id}
                    style={{
                      height: 'auto',
                      width: '100%',
                    }}
                    src={content}
                    alt="vehicle_image"
                  />
                ),
            )}
          </div>
        </div>
        <div
          style={{
            gridColumn: 'span 2',
            height: 'auto',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Responsável</p>
          <div
            style={{
              display: 'grid',
              height: 'auto',
              width: '100%',
              gap: '0.5rem',
              paddingTop: '0.5rem',
            }}
          >
            {body?.responsible_signature?.answers.map(
              ({ id, content }) =>
                content && (
                  <img
                    key={id}
                    style={{
                      height: 'auto',
                      width: '100%',
                    }}
                    src={content}
                    alt="signature"
                  />
                ),
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
