import { useReport } from './hooks/use-context';

export function Pictures() {
  const { body } = useReport();

  const { pictures } = body;

  return (
    <div style={{ marginBottom: '4px' }}>
      <div
        style={{
          width: '100%',
          backgroundColor: '#d3d3d3',
          textAlign: 'center',
          marginBottom: '4px',
          padding: '0.5rem',
          fontWeight: 'bold',
          fontSize: '1.2rem',
        }}
      >
        REGISTRO FOTOGRÁFICO
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
          gap: '1rem',
          backgroundColor: '#ffffcc',
          padding: '1rem',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {pictures?.answers?.map((answer) => (
          <div
            key={answer.id}
            style={{
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                objectFit: 'contain',
                width: '100%',
                height: '100%',
                maxHeight: '300px',
              }}
              src={`${answer.content || ''}`}
              alt="img"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
