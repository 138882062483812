import { ActionItem } from '@/components_v2/ContextMenu';
import FilterContextMenu from '@/components_v2/FilterContextMenu';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Popup from 'reactjs-popup';

import ItemExtra from './components/ItemExtra';
import { TriggerProps } from './interfaces';

const FilterTrigger: React.FC<TriggerProps> = ({ filter }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Popup
      nested
      trigger={
        <div style={{ width: isMobile ? '250px' : '450px' }}>
          <ActionItem
            text={filter.title}
            extra={<ItemExtra filter={filter} />}
            icon={<></>}
            clickable
            hasChevron
          />
        </div>
      }
      position={['right center', 'left center', 'bottom center', 'left bottom']}
      closeOnDocumentClick
      arrow={false}
      on={['click']}
    >
      <FilterContextMenu filter={filter} />
    </Popup>
  );
};

export default FilterTrigger;
