/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-types */

import { ListBox, useItem } from '@/components_v2/ListBox';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  ButtonProps,
  chakra,
  Checkbox,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';
import { castArray } from 'lodash';
import React, { PropsWithChildren } from 'react';
import { BiSearch } from 'react-icons/bi';

import {
  DialogSelectContext,
  TBaseItem,
  TRenderItem,
  useDialogSelect,
  useDialogSelectContext,
  UseDialogSelectProps,
} from './useDialogSelect';
import { usePaginationObserver } from './utils';

function Search() {
  const { list } = useDialogSelectContext();
  return (
    <InputGroup>
      <Input
        _focus={{ _focus: 'none' }}
        placeholder="Pesquisar"
        onChange={(e) => {
          list.setFilterText(e.target.value);
        }}
        backgroundColor="white"
      />
      <InputRightElement>
        <BiSearch color="#E2E8F0" />
      </InputRightElement>
    </InputGroup>
  );
}

function DefaultItem({ children }: { children: React.ReactNode }) {
  const { isSelected } = useItem();

  return (
    <chakra.div
      display="flex"
      flexDir="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {children}
      <chakra.div>
        <Checkbox pointerEvents="none" size="lg" isChecked={isSelected} />
      </chakra.div>
    </chakra.div>
  );
}

function List() {
  const {
    list,
    selectionMode,
    selectionBehavior,
    onSelectionChange,
    disabledKeys,
    renderItem,
    selectedKeys,
  } = useDialogSelectContext();

  const { observerTarget } = usePaginationObserver({
    onPaginate: () => {
      list.loadMore();
    },
  });

  return (
    <chakra.div overflowY="auto">
      <ListBox.Root
        items={list.items}
        selectedKeys={selectedKeys}
        // defaultSelectedKeys={selectedKeys}
        disabledKeys={disabledKeys}
        onSelectionChange={onSelectionChange}
        selectionBehavior={selectionBehavior}
        selectionMode={selectionMode}
      >
        {(item) => (
          <ListBox.Item key={item.id}>
            <DefaultItem>{renderItem?.(item)}</DefaultItem>
          </ListBox.Item>
        )}
      </ListBox.Root>
      {!!list.items.length && <chakra.div ref={observerTarget} />}
      {list.isLoading && (
        <chakra.div w="full" py="1" display="flex" alignItems="center">
          <Spinner mx="auto" justifySelf="center" />
        </chakra.div>
      )}
    </chakra.div>
  );
}

function Dialog() {
  const {
    dialogProps,
    confirmLabel,
    header,
    onConfirm,
  } = useDialogSelectContext();

  return (
    <Modal size="2xl" {...dialogProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />

        <ModalBody p="0">
          <chakra.div
            overflowY="clip"
            w="full"
            p="6"
            maxH="500px"
            minH="300px"
            display="flex"
            gap="4"
            flexDir="column"
          >
            <Search />
            <List />
          </chakra.div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="solid"
            colorScheme="brand"
            w="full"
            size="md"
            onClick={onConfirm}
          >
            {confirmLabel || 'Confirmar'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function Root({ children, ...rest }: PropsWithChildren<UseDialogSelectProps>) {
  const value = useDialogSelect(rest);

  return (
    <DialogSelectContext value={value}>
      <Dialog />
      {children}
    </DialogSelectContext>
  );
}

function RenderItem<T extends object = {}>({
  children,
}: {
  children: TRenderItem<T>;
}) {
  const { setRenderItem } = useDialogSelectContext();

  React.useLayoutEffect(() => {
    setRenderItem(children as any);
  }, [children, setRenderItem]);

  return null;
}

type TriggerButtonProps<T extends object = {}> = Omit<
  ButtonProps,
  'children'
> & {
  children?: (state: TBaseItem<T>[]) => JSX.Element | string;
};

function TriggerButton({
  leftIcon,
  rightIcon,
  children,
  placeholder,
  ...rest
}: TriggerButtonProps) {
  const { selectedItems, dialogProps } = useDialogSelectContext();

  const selectedItemsArr = castArray(selectedItems);
  return (
    <Button
      onClick={dialogProps.onOpen}
      display="flex"
      fontWeight="normal"
      size="md"
      variant="outline"
      alignItems="center"
      justifyContent="space-between"
      flexDir="row"
      width="full"
      whiteSpace="nowrap"
      position="relative"
      overflow="hidden"
      ps="2"
      pe="3"
      {...rest}
    >
      <chakra.span
        wordBreak="break-all"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
      >
        {selectedItemsArr.length
          ? children
            ? children(selectedItems)
            : selectedItems.map((e) => e.name).join(',')
          : placeholder || 'Selecione...'}
      </chakra.span>
      <chakra.span w="4">
        <Icon as={ChevronDownIcon} fontSize="2xl" />
      </chakra.span>
    </Button>
  );
}

export const DialogSelect = {
  Root,
  Button: TriggerButton,
  RenderItem,
};
