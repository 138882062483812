import { useReport } from './hooks/use-context';

export function InitialInfos() {
  const { body, header } = useReport();

  const inspectionType = body?.[0]?.sections
    .find((section) => section.section_name === 'Informações')
    ?.questions.find((question) => question.name === 'Tipo de Inspeção')
    ?.answers?.[0]?.option;

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '13fr 12fr 5fr',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateRows: '1fr 1fr',
        }}
      >
        <div
          style={{
            display: 'flex',
            padding: '4px',
            borderRight: '1px #000 solid',
            borderBottom: '1px #000 solid',
          }}
        >
          <div style={{ fontWeight: 'bold' }}>Área/Setor:</div>
          <div style={{ flex: 1, textAlign: 'center' }}>Agrícola</div>
        </div>

        <div
          style={{
            display: 'flex',
            padding: '4px',
            borderRight: '1px #000 solid',
            borderBottom: '1px #000 solid',
          }}
        >
          <div style={{ fontWeight: 'bold' }}>Local:</div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            {header?.local?.name}
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateRows: '1fr 1fr',
        }}
      >
        <div
          style={{
            display: 'flex',
            padding: '4px',
            borderRight: '1px #000 solid',
            borderBottom: '1px #000 solid',
          }}
        >
          <div style={{ fontWeight: 'bold' }}>Data Inspeção:</div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            {' '}
            {header.event_created_at}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            padding: '4px',
            borderRight: '1px #000 solid',
            borderBottom: '1px #000 solid',
          }}
        >
          <div style={{ fontWeight: 'bold' }}>Tipo de Inspeção:</div>
          <div style={{ flex: 1, textAlign: 'center' }}>{`(${
            inspectionType === 'IG' ? 'x' : ' '
          }) IG  (${inspectionType === 'ST' ? 'x' : ' '}) ST  (${
            inspectionType === 'GE' ? 'x' : ' '
          }) GE  (${inspectionType === 'RE' ? 'x' : ' '}) RE`}</div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          fontWeight: 'bold',
          alignItems: 'center',
          borderBottom: '1px #000 solid',
        }}
      >
        % Conformidade
      </div>
    </div>
  );
}
