import { useReport } from './hooks/use-context';

export function OccurrenceDescription() {
  const { body } = useReport();

  const { cause, occurence_description } = body;

  const cellStyle = {
    padding: '0px 2px',
    border: '1px solid black',
    textAlign: 'left' as const,
  };

  const valueStyle = {
    textAlign: 'center' as const,
    backgroundColor: '#ffffcc',
  };

  return (
    <div
      style={{
        marginBottom: '4px',
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '4fr 2fr 4fr',
          marginBottom: '4px',
          gap: '4px',
        }}
      >
        <div
          style={{
            width: '100%',
            backgroundColor: '#d3d3d3',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          DESCRIÇÃO DA OCORRÊNCIA
        </div>

        <div
          style={{
            ...cellStyle,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          CAUSA LEVANTADA
        </div>

        <div style={{ ...cellStyle, ...valueStyle }}>
          {cause?.answers?.map((answer) => answer.option).join('; ')}
        </div>
      </div>
      <div style={{ ...cellStyle, ...valueStyle, padding: '4px' }}>
        {occurence_description?.answers?.[0]?.content}
      </div>
    </div>
  );
}
