import { ACTION_ITEM_HEIGHT } from '@/components_v2/ContextMenu/constants';
import styled from 'styled-components';

type ContainerProps = {
  isMobile?: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: ${({ isMobile }) => (isMobile ? '250px' : '450px')};
  height: 100%;
  max-height: 300px;
  overflow: scroll;
`;

export const HorizontalLineContainer = styled.div`
  width: 100%;
  padding: 0px -${ACTION_ITEM_HEIGHT}px;
`;
