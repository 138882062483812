import { QuestionTypes } from '@/gql/graphql';
import { fetchAutomationProperties } from '@/screens/Tickets/hooks/useFetchAutomationProperties';
import { fetchAutomationSteps } from '@/screens/Tickets/hooks/useFetchAutomationSteps';
import { fetchFormularies } from '@/screens/Tickets/hooks/useFetchFormularies';
import { fetchLocals } from '@/screens/Tickets/hooks/useFetchLocals';
import { fetchOptions } from '@/screens/Tickets/hooks/useFetchOptions';
import { fetchQuestions } from '@/screens/Tickets/hooks/useFetchQuestions';
import { fetchUsers } from '@/screens/Tickets/hooks/useFetchUsers';
import { chakra, Icon } from '@chakra-ui/react';
import React from 'react';
import { BiHelpCircle, BiUserCircle } from 'react-icons/bi';

import { DialogSelect } from '../misc/DialogSelect/DialogSelect';
import { UseDialogSelectProps } from '../misc/DialogSelect/useDialogSelect';

type SelectorProps = {
  value?: string[];
  triggerIds?: string[];
} & Pick<
  UseDialogSelectProps,
  | 'initialOptions'
  | 'selectionMode'
  | 'onChange'
  | 'defaultSelectedKeys'
  | 'onSelectionChange'
>;

export function FormularySelect({
  value,
  initialOptions,
  selectionMode,
  triggerIds,
  onChange,
  onSelectionChange,
}: SelectorProps) {
  const fetcher: UseDialogSelectProps['fetchItems'] = React.useCallback(
    async ({ cursor, filterText }) => {
      const { cursor: _cursor, items } = await fetchFormularies({
        after: cursor,
        search: filterText,
        first: 500,
        triggerIds,
      });

      return {
        items: items.map((e) => ({ id: e.id, name: `${e.name}`, data: {} })),
        cursor: _cursor,
      };
    },
    [],
  );

  return (
    <DialogSelect.Root
      selectionMode={selectionMode}
      selectionBehavior="toggle"
      fetchItems={fetcher}
      defaultSelectedKeys={value}
      initialOptions={initialOptions}
      onChange={onChange}
      onSelectionChange={onSelectionChange}
      header="Formulário"
    >
      <DialogSelect.Button w="full" minW="0%" placeholder="selecione...">
        {(state) => {
          return state.map((item) => item.name).join(', ');
        }}
      </DialogSelect.Button>
    </DialogSelect.Root>
  );
}

export function OptionSelect({
  value,
  initialOptions,
  selectionMode,
  triggerIds,
  onSelectionChange,
  onChange,
}: SelectorProps) {
  const fetcher: UseDialogSelectProps['fetchItems'] = React.useCallback(
    async ({ cursor, filterText }) => {
      const { cursor: _cursor, items } = await fetchOptions({
        after: cursor,
        search: filterText,
        first: 20,
        triggerIds,
      });

      return {
        items: items.map((item) => ({
          id: item.id,
          name: `${item.name}`,
          data: {
            formularyName: item.question?.formulary?.name,
            questionName: item.question?.name,
          },
        })),
        cursor: _cursor,
      };
    },
    [],
  );

  return (
    <DialogSelect.Root
      selectionMode={selectionMode}
      selectionBehavior="toggle"
      fetchItems={fetcher}
      onSelectionChange={onSelectionChange}
      defaultSelectedKeys={value}
      initialOptions={initialOptions}
      onChange={onChange}
      header="Formulário"
    >
      <DialogSelect.RenderItem<{ formularyName: string; questionName: string }>>
        {({ data, name }) => (
          <chakra.div display="flex" flexDir="row" overflow="hidden" gap="2">
            <Icon fontSize="xl" as={BiHelpCircle} />
            <chakra.div flexDir="column">
              <chakra.p>{name}</chakra.p>
              <chakra.div bg="gray.100" rounded="md" p="2">
                <chakra.p>Pergunta: {data?.questionName}</chakra.p>
                <chakra.p>Formulário: {data?.formularyName}</chakra.p>
              </chakra.div>
            </chakra.div>
          </chakra.div>
        )}
      </DialogSelect.RenderItem>
      <DialogSelect.Button w="full" minW="0%" placeholder="selecione...">
        {(state) => {
          return state.map((item) => item.name).join(', ');
        }}
      </DialogSelect.Button>
    </DialogSelect.Root>
  );
}

export function QuestionSelect({
  value,
  initialOptions,
  selectionMode,
  onChange,
  onSelectionChange,
  questionTypes,
  parentOptionId,
  parentQuestionId,
  formularyId,
  triggerIds,
}: SelectorProps & {
  formularyId?: string;
  questionTypes?: QuestionTypes[];
  parentOptionId?: string;
  parentQuestionId?: string;
}) {
  const fetcher: UseDialogSelectProps['fetchItems'] = React.useCallback(
    async ({ cursor, filterText }) => {
      const { cursor: _cursor, items } = await fetchQuestions({
        after: cursor,
        search: filterText,
        formularyId,
        questionTypes,
        parentOptionId,
        parentQuestionId,
        first: 20,
        triggerIds,
      });

      return {
        items: items.map((item) => ({
          id: item.id,
          name: `${item.name}`,
          data: {
            formularyName: item.formulary?.name,
          },
        })),
        cursor: _cursor,
      };
    },
    [formularyId, questionTypes],
  );

  return (
    <DialogSelect.Root
      selectionMode={selectionMode}
      selectionBehavior="toggle"
      fetchItems={fetcher}
      onChange={onChange}
      defaultSelectedKeys={value}
      initialOptions={initialOptions}
      onSelectionChange={onSelectionChange}
      header="Pergunta"
    >
      <DialogSelect.RenderItem<{ formularyName: string }>>
        {({ data, name }) => (
          <chakra.div
            w="full"
            pe="4"
            display="flex"
            flexDir="row"
            overflow="hidden"
            gap="2"
          >
            <Icon fontSize="2xl" as={BiHelpCircle} />
            <chakra.div w="full" flexDir="column">
              <chakra.p>{name}</chakra.p>
              <chakra.div bg="blackAlpha.50" rounded="md" p="2">
                <chakra.p>Formulário: {data?.formularyName}</chakra.p>
              </chakra.div>
            </chakra.div>
          </chakra.div>
        )}
      </DialogSelect.RenderItem>
      <DialogSelect.Button w="full" minW="0%" placeholder="selecione...">
        {(state) => {
          return state.map((item) => item.name).join(', ');
        }}
      </DialogSelect.Button>
    </DialogSelect.Root>
  );
}

export function UserSelect({
  value,
  initialOptions,
  selectionMode,
  onChange,
  onSelectionChange,
}: SelectorProps) {
  const fetcher: UseDialogSelectProps['fetchItems'] = React.useCallback(
    async ({ cursor, filterText }) => {
      const { cursor: _cursor, items } = await fetchUsers({
        after: cursor,
        search: filterText,
        first: 20,
      });

      return {
        items: items.map((item) => ({
          id: item.id,
          name: `${item.fullName}`,
          data: {
            email: item.email,
          },
        })),
        cursor: _cursor,
      };
    },
    [],
  );

  return (
    <DialogSelect.Root
      selectionMode={selectionMode}
      selectionBehavior="toggle"
      fetchItems={fetcher}
      onChange={onChange}
      defaultSelectedKeys={value}
      initialOptions={initialOptions}
      onSelectionChange={onSelectionChange}
      header="Responsável pela etapa"
    >
      <DialogSelect.RenderItem<{ email: string }>>
        {({ data, name }) => (
          <chakra.div
            w="full"
            pe="4"
            display="flex"
            flexDir="row"
            overflow="hidden"
            gap="2"
          >
            <Icon fontSize="2xl" as={BiUserCircle} />
            <chakra.div w="full" flexDir="column">
              <chakra.p>{name}</chakra.p>
              <chakra.div bg="blackAlpha.50" rounded="md" p="2">
                <chakra.p>{data?.email}</chakra.p>
              </chakra.div>
            </chakra.div>
          </chakra.div>
        )}
      </DialogSelect.RenderItem>
      <DialogSelect.Button w="full" minW="0%" placeholder="selecione..." />
    </DialogSelect.Root>
  );
}

export function LocalSelect({
  value,
  initialOptions,
  selectionMode,
  onChange,
  onSelectionChange,
}: SelectorProps) {
  const fetcher: any = React.useCallback(
    async (searchText?: string) => {
      const { cursor: _cursor, items } = await fetchLocals({
        search: searchText,
      });

      return {
        items:
          items?.map((item) => ({
            id: item.id,
            name: `${item.name}`,
          })) || [],
        cursor: _cursor,
      };
    },
    [],
  );

  return (
    <DialogSelect.Root
      selectionMode={selectionMode}
      selectionBehavior="toggle"
      fetchItems={e => fetcher(e.filterText)}
      onChange={onChange}
      defaultSelectedKeys={value}
      initialOptions={initialOptions}
      onSelectionChange={onSelectionChange}
      header="Local de execução do checklist"
    >
      <DialogSelect.RenderItem<{ email: string }>>
        {({ data, name }) => (
          <chakra.div
            w="full"
            pe="4"
            display="flex"
            flexDir="row"
            overflow="hidden"
            gap="2"
          >
            <Icon fontSize="2xl" as={BiUserCircle} />
            <chakra.div w="full" flexDir="column">
              <chakra.p>{name}</chakra.p>
            </chakra.div>
          </chakra.div>
        )}
      </DialogSelect.RenderItem>
      <DialogSelect.Button w="full" minW="0%" placeholder="selecione...">
        {(state) => {
          return state.map((item) => item.name).join(', ');
        }}
      </DialogSelect.Button>
    </DialogSelect.Root>
  );
}

export function AutomationPropertySelect({
  value,
  initialOptions,
  selectionMode,
  onChange,
  onSelectionChange,
  automationFlowId,
}: SelectorProps & { automationFlowId: string }) {
  const fetcher: UseDialogSelectProps['fetchItems'] = React.useCallback(
    async ({ filterText }) => {
      const { cursor: _cursor, items } = await fetchAutomationProperties({
        search: filterText,
        automationFlowId,
      });

      return {
        items: items.map((item) => ({
          id: item.id,
          name: `${item.name}`,
        })),
        cursor: _cursor,
      };
    },
    [automationFlowId],
  );

  return (
    <DialogSelect.Root
      selectionMode={selectionMode}
      selectionBehavior="toggle"
      fetchItems={fetcher}
      onChange={onChange}
      defaultSelectedKeys={value}
      initialOptions={initialOptions}
      onSelectionChange={onSelectionChange}
      header="Atributos do fluxo"
    >
      <DialogSelect.Button w="full" minW="0%" placeholder="selecione..." />
    </DialogSelect.Root>
  );
}

export function AutomationStepsSelect({
  value,
  initialOptions,
  selectionMode,
  onChange,
  onSelectionChange,
  automationFlowId,
  triggerIds,
}: SelectorProps & { automationFlowId: string }) {
  const fetcher: UseDialogSelectProps['fetchItems'] = React.useCallback(
    async ({ cursor, filterText }) => {
      const { cursor: _cursor, items } = await fetchAutomationSteps({
        automationFlowId,
        search: filterText,
        after: cursor,
        first: 20,
        triggerIds,
      });

      return {
        items: items.map((item) => ({
          id: item.id,
          name: `${item.name}`,
        })),
        cursor: _cursor,
      };
    },
    [automationFlowId],
  );

  return (
    <DialogSelect.Root
      selectionMode={selectionMode}
      selectionBehavior="toggle"
      fetchItems={fetcher}
      onChange={onChange}
      defaultSelectedKeys={value}
      initialOptions={initialOptions}
      onSelectionChange={onSelectionChange}
      header="Etapas do fluxo"
    >
      <DialogSelect.Button w="full" minW="0%" placeholder="selecione..." />
    </DialogSelect.Root>
  );
}
