import { useReport } from './hooks/use-context';

export function InitialInfos() {
  const { body } = useReport();

  const {
    area,
    classification,
    date,
    event,
    impact_level,
    probability,
    recurrence,
    severity,
    shift,
  } = body;

  const cellStyle = {
    padding: '0px 2px',
    border: '1px solid black',
    textAlign: 'left' as const,
    maxHeight: '20px',
  };

  const valueStyle: any = {
    textAlign: 'center' as const,
    backgroundColor: '#ffffcc',
    maxHeight: '20px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '3fr 2fr 2fr 2fr 2fr',
        fontFamily: 'Arial, sans-serif',
        gap: '10px',
        marginBottom: '4px',
      }}
    >
      {/* Headers */}
      <div
        style={{
          display: 'grid',
          gridTemplateRows: 'repeat(5, 1fr)',
        }}
      >
        <div
          style={{
            gridRow: '1 / span 4',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            backgroundColor: '#d3d3d3',
            fontWeight: 'bold',
            padding: '0px 5px',
            border: '1px solid black',
            borderBottom: 'none',
            fontSize: '1.5rem',
          }}
        >
          Segurança
        </div>
        <div
          style={{
            gridRow: '5',
            textAlign: 'center',
            backgroundColor: '#d3d3d3',
            fontWeight: 'bold',
            padding: '0px 5px',
            border: '1px solid black',
          }}
        >
          Meio Ambiente
        </div>
      </div>

      {/* col 1 */}
      <div
        style={{
          display: 'grid',
          gridTemplateRows: '1fr repeat(4, 1fr)',
          rowGap: '4px',
        }}
      >
        <div style={{ ...cellStyle, border: 'none' }}></div> {/* Empty row */}
        <div style={cellStyle}>Probabilidade</div>
        <div style={cellStyle}>Severidade</div>
        <div style={cellStyle}>Nível de Impacto</div>
        <div style={cellStyle}>Evento</div>
      </div>

      {/* col 2 */}
      <div
        style={{
          display: 'grid',
          gridTemplateRows: '1fr repeat(4, 1fr)',
          rowGap: '4px',
        }}
      >
        <div style={{ ...cellStyle, border: 'none' }}></div> {/* Empty row */}
        <div style={{ ...cellStyle, ...valueStyle }}>
          {probability?.answers?.[0]?.option}
        </div>
        <div style={{ ...cellStyle, ...valueStyle }}>
          {severity?.answers?.[0]?.option}
        </div>
        <div style={{ ...cellStyle, ...valueStyle }}>
          {impact_level?.answers?.[0]?.content}
        </div>
        <div style={{ ...cellStyle, ...valueStyle }}>
          {event?.answers?.[0]?.content}
        </div>
      </div>

      {/* col 3 */}
      <div
        style={{
          display: 'grid',
          gridTemplateRows: 'repeat(5, 1fr)',
          rowGap: '4px',
        }}
      >
        <div style={cellStyle}>Reincidente?</div>
        <div style={cellStyle}>Data</div>
        <div style={cellStyle}>Turno</div>
        <div style={cellStyle}>Classificação</div>
        <div style={cellStyle}>Área</div>
      </div>

      {/* col 4 */}
      <div
        style={{
          display: 'grid',
          gridTemplateRows: 'repeat(5, 1fr)',
          rowGap: '4px',
        }}
      >
        <div style={{ ...cellStyle, ...valueStyle }}>
          {recurrence?.answers?.[0]?.option}
        </div>
        <div style={{ ...cellStyle, ...valueStyle }}>
          {date?.answers?.[0]?.content}
        </div>
        <div style={{ ...cellStyle, ...valueStyle }}>
          {shift?.answers?.[0]?.option}
        </div>
        <div style={{ ...cellStyle, ...valueStyle }}>
          {classification?.answers?.[0]?.option}
        </div>
        <div style={{ ...cellStyle, ...valueStyle }}>
          {area?.answers?.[0]?.option}
        </div>
      </div>
    </div>
  );
}
