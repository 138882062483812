import constants from '@/Constants';
import { MacatubaReport } from '@/modules/pdf/macatuba';
import { MacatubaReportData } from '@/modules/pdf/macatuba/types';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

export const ReportMacatuba: React.FC<{
  reportId: string;
  workspaceSlug: string;
}> = ({ reportId, workspaceSlug }) => {
  const [report, setReport] = useState<MacatubaReportData | null>(null);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const url = constants.REPORTS_API_URL || '';

        const parsedUrl = url
          .toLowerCase()
          .replace('{{workspace}}', workspaceSlug);

        const { data, status } = await axios.get<MacatubaReportData>(
          `${parsedUrl}pdf_json_by_export_id?export_id=${reportId}`,
          {
            headers: {
              Authorization: constants.REPORTS_API_AUTHORIZATION_TOKEN,
            },
          },
        );

        if (status === 200) {
          setReport(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchReport();
  }, [reportId, workspaceSlug]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <MacatubaReport
        report={{
          body: report?.body,
          header: report?.header,
        }}
      />
    </div>
  );
};
