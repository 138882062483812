import { MacatubaReportData } from './types';

import './styles.css';

export function MacatubaReport(props: { report: MacatubaReportData }) {
  const { report } = props;

  return (
    <div className="container">
      <header className="header">
        <div className="logo-container">
          <img src={report?.header?.logo || ''} alt="logo" />
        </div>
        <div className="header-info">
          <h2 className="subtitle">{report?.header?.sub_title}</h2>
          <h1 className="title">{report?.header?.title}</h1>
          <p className="address">{report?.header?.address}</p>
          <p className="phone">{report?.header?.phone}</p>
        </div>
        <div className="spacer" />
      </header>

      <div className="content">
        <h1 className="section-title">
          ATO FISCALIZATÓRIO Nº {report?.body?.number_notification}
        </h1>

        {!!report?.body?.owner_infos && (
          <section className="section">
            <span className="section-heading">
              IDENTIFICAÇÃO DO PROPRIETÁRIO OU RESPONSÁVEL
            </span>
            <p className="text-small">
              Nome: {report?.body?.owner_infos?.name}
            </p>
            <p className="text-small">
              CPF/CNPJ: {report?.body?.owner_infos?.document}
            </p>
            <p className="text-small">
              Endereço: {report?.body?.owner_infos?.local}
            </p>
          </section>
        )}

        {!!report?.body?.local && (
          <section className="section">
            <span className="section-heading">LOCAL FISCALIZADO</span>
            <p className="text-small">Nº Cadastro: {report?.body?.local?.id}</p>
            <p className="text-small">
              Endereço: {report?.body?.local?.address}
            </p>
          </section>
        )}

        {!!report?.body?.occurrence && (
          <section className="section">
            <span className="section-heading">OCORRÊNCIA:</span>
            <p className="text-small">
              Data: {report?.body?.occurrence?.date.split(' ')[0]} - Hora:{' '}
              {report?.body?.occurrence?.date.split(' ')[1]}
            </p>
            <p className="text-small">
              Fundamentação Legal: {report?.body?.occurrence?.description}
            </p>
            <p className="text-small">Fato: {report?.body?.occurrence?.fact}</p>
          </section>
        )}

        <div>
          <section className="images">
            <div className="image-container">
              {!!report?.body?.pictures?.[0] && (
                <img
                  src={report?.body?.pictures?.[0] || ''}
                  alt=""
                  className="image"
                />
              )}
            </div>
            <div className="image-container">
              {!!report?.body?.pictures?.[1] && (
                <img
                  src={report?.body?.pictures?.[1] || ''}
                  alt=""
                  className="image"
                />
              )}
            </div>
          </section>

          {!!report.body?.supervisor_infos?.geolocation && (
            <p className="geo-info">
              Geolozalização: Lat:{' '}
              {report.body?.supervisor_infos?.geolocation?.latitude} Lng:{' '}
              {report.body?.supervisor_infos?.geolocation?.longitude}
            </p>
          )}
        </div>

        {!!report?.body?.orientation && (
          <section className="section">
            <span className="section-heading">ORIENTAÇÃO/PROVIDÊNCIA:</span>
            <p className="text-small">{report?.body?.orientation}</p>
            <p className="text-medium">{report?.body?.until_date}</p>
          </section>
        )}

        {!!report?.body?.supervisor_infos && (
          <section className="section">
            <span className="section-heading">IDENTIFICAÇÃO DO FISCAL</span>
            <p className="text-small">
              Nome: {report?.body?.supervisor_infos?.name}
            </p>
            <p className="text-small">
              Função/Cargo: {report?.body?.supervisor_infos?.function}
            </p>
            <p className="text-small">
              Matrícula: {report?.body?.supervisor_infos?.id}
            </p>
          </section>
        )}
      </div>

      <div className="footer">
        {!!report?.body?.supervisor_infos?.signature && (
          <div className="signature">
            <img
              src={report?.body?.supervisor_infos?.signature}
              alt=""
              className="signature-image"
            />
          </div>
        )}
        Macatuba, {report?.body?.occurrence?.date}
      </div>
    </div>
  );
}
