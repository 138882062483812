import constants from '@/Constants';
import { EventReport } from '@/modules/pdf/event-report';
import { OmniReport } from '@/modules/pdf/omni';
import { OmniReportResponse } from '@/modules/pdf/omni/types';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

export const ReportOmni: React.FC<{
  reportId: string;
}> = ({ reportId }) => {
  const [report, setReport] = useState<OmniReportResponse | null>(null);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const url = constants.REPORTS_API_URL || '';

        const parsedUrl = url.toLowerCase().replace('{{workspace}}', 'omni');

        const { data, status } = await axios.get<OmniReportResponse>(
          `${parsedUrl}pdf_json_by_export_id?export_id=${reportId}`,
          {
            headers: {
              Authorization: constants.REPORTS_API_AUTHORIZATION_TOKEN,
            },
          },
        );

        if (status === 200) {
          setReport(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchReport();
  }, [reportId]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      {report?.header?.parentable_type === 'ticket' ? (
        <OmniReport
          report={{
            body: report?.body,
            header: report?.header,
          }}
        />
      ) : (
        <EventReport
          reportData={{
            body: {
              items: report?.body || [],
            },
            header: report?.header,
          }}
        />
      )}
    </div>
  );
};
