/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import { useModals } from '@/hooks/ModalManager';
import camelToSnake from '@/utils/camelToSnake';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useCreateTemplateTask,
  useTemplateTaskQuery,
  useUpdateTemplateTask,
} from '../hooks';
import { ITemplateTasksListType } from '../types';
import { FormSelect, LocalSelect, UserSelect } from './FormInputs';
import { QrCodeGenerator } from './QrCodeGenerator';
import { RemoteSelectOption } from './RemoteSelect';

type TemplateTaskFormPayload = {
  id?: string;
  name: string;
  dueIn?: number;
  local?: RemoteSelectOption;
  checklist?: RemoteSelectOption;
  member?: RemoteSelectOption;
};

const mapFormValues = (
  item: ITemplateTasksListType,
): TemplateTaskFormPayload => {
  return {
    id: item.id,
    name: item.name,
    checklist: item.formularyId
      ? {
          value: item.formularyId,
          label: `${item.formularyName}`,
        }
      : undefined,
    local: item.localId
      ? {
          value: item.localId,
          label: `${item.localName}`,
        }
      : undefined,
    dueIn: item.dueIn ? item.dueIn / 3600000 : undefined,
    member: item.memberId
      ? {
          label: `${item.memberName}`,
          value: item.memberId,
        }
      : undefined,
  };
};

const toMillis = (hours: number | undefined) => {
  return hours ? hours * 3600000 : undefined;
};

export function TemplateTaskCreateForm(props: {
  templateId?: string;
  onClose?: () => void;
}) {
  const { onClose } = props;
  const [templateId, setTemplateId] = React.useState(props.templateId);

  const { t } = useTranslation();
  const toast = useToast();
  const {
    mutateAsync: createMutation,
    isLoading: isCreating,
  } = useCreateTemplateTask();
  const {
    mutateAsync: updateMutation,
    isLoading: isUpdating,
  } = useUpdateTemplateTask(templateId!);

  const { data: remoteTemplateTask, isLoading, refetch } = useTemplateTaskQuery(
    templateId,
  );

  const formMethods = useForm<TemplateTaskFormPayload>({
    defaultValues: remoteTemplateTask
      ? mapFormValues(remoteTemplateTask)
      : undefined,
  });

  const {
    register,
    control,
    reset,
    formState: { errors, isDirty },
    handleSubmit,
  } = formMethods;

  React.useEffect(() => {
    if (remoteTemplateTask) {
      const vals = mapFormValues(remoteTemplateTask);

      reset(vals);
    }
  }, [remoteTemplateTask, reset]);

  const onCreate = React.useCallback(
    async (payload: TemplateTaskFormPayload) => {
      const params = camelToSnake({
        name: payload.name,
        formularyId: payload.checklist?.value,
        dueIn: toMillis(payload.dueIn),
        localId: payload.local?.value,
        memberId: payload.member?.value,
      });
      const response = await createMutation(params);
      if (response?.body?.id) {
        setTemplateId(response?.body?.id);
        toast({
          title: 'Template salvo com sucesso!',
          status: 'success',
        });
        refetch();
      }
    },
    [createMutation, refetch, toast],
  );

  const onUpdate = React.useCallback(
    async (payload: TemplateTaskFormPayload) => {
      const params = camelToSnake({
        name: payload.name,
        formularyId: payload.checklist?.value,
        dueIn: toMillis(payload.dueIn),
        localId: payload.local?.value || null,
        memberId: payload.member?.value || null,
      });
      const response = await updateMutation(params);
      if (response?.body?.id) {
        setTemplateId(response?.body?.id);
        toast({
          title: 'Template salvo com sucesso!',
          status: 'success',
        });
        refetch();
        onClose?.();
      }
    },
    [onClose, toast, updateMutation, refetch],
  );

  const onSubmit = React.useCallback(
    (payload: TemplateTaskFormPayload) => {
      try {
        if (templateId) {
          onUpdate(payload);
        } else {
          onCreate(payload);
        }
      } catch (e) {
        if (e instanceof Error) {
          toast({
            title: 'Ocorreu um erro!',
            description: e.message,
          });
        }
      }
    },
    [onCreate, onUpdate, templateId, toast],
  );

  return (
    <Flex
      as="form"
      flexDir="column"
      w="full"
      maxH="full"
      h="full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack flex="1" overflowY="auto" overflowX="hidden" p={8} spacing={14}>
        <QrCodeGenerator isLoading={isLoading} templateId={templateId} />
        <FormControl isRequired isInvalid={!!errors.name?.message}>
          <FormLabel fontWeight="bold">{t('tasks.form.name')}</FormLabel>
          <Input {...register('name', { required: 'Nome é obrigatório' })} />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          <FormHelperText>
            A tarefa gerada a partir deste template será identificada pelo nome
            que você configurar, seguida de um identificador aleatório (exemplo:
            Tarefa - #WEC1234)
          </FormHelperText>
        </FormControl>

        <Controller
          control={control}
          name="checklist"
          rules={{ required: 'Formulário é obrigatório' }}
          render={({ field: { onChange, value }, fieldState }) => (
            <FormControl isRequired isInvalid={fieldState.invalid}>
              <FormLabel fontWeight="bold">
                {t('tasks.form.checklist')}
              </FormLabel>
              <FormSelect onChange={onChange} value={value} />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              <FormHelperText>
                Configure o checklist a ser executado nas tarefas criadas
                através deste template.
              </FormHelperText>
            </FormControl>
          )}
        />

        <FormControl>
          <FormLabel fontWeight="bold">{t('tasks.form.local')}</FormLabel>
          <Controller
            control={control}
            name="local"
            render={({ field: { onChange, value } }) => (
              <LocalSelect onChange={onChange} value={value} />
            )}
          />
          <FormHelperText>
            A tarefa gerada a partir deste template pode ser associada
            opcionalmente à um local de execução.
          </FormHelperText>
        </FormControl>

        <Controller
          control={control}
          name="member"
          render={({ field: { onChange, value } }) => (
            <FormControl>
              <FormLabel fontWeight="bold">
                {t('tasks.form.responsible')}
              </FormLabel>
              <UserSelect onChange={onChange} value={value} />
              <FormHelperText>
                Se um membro for configurado neste template, todas as tarefas
                criadas a partir do mesmo irão ser direcionadas à este membro.
              </FormHelperText>
            </FormControl>
          )}
        />

        <FormControl>
          <FormLabel fontWeight="bold">{t('tasks.form.sla')}</FormLabel>
          <HStack>
            <NumberInput>
              <NumberInputField {...register('dueIn')} />
            </NumberInput>
            <Text fontWeight="bold">Hours</Text>
          </HStack>
          <FormHelperText>
            A tarefa será considerada atrasada quando se passar a quantidade de
            horas que você configurar, a partir da criação da mesma.
          </FormHelperText>
        </FormControl>
      </Stack>
      <Flex
        p="5"
        alignItems="flex-start"
        justifyContent="flex-end"
        h="36"
        borderTopWidth="thin"
      >
        <Button
          isLoading={isCreating || isUpdating}
          onClick={onClose}
          size="md"
          variant="outline"
          mr={3}
        >
          Cancelar
        </Button>
        <Button
          isLoading={isCreating || isUpdating}
          size="md"
          type="submit"
          colorScheme="brand"
          disabled={!isDirty}
        >
          Salvar
        </Button>
      </Flex>
    </Flex>
  );
}

export function useTemplateTaskCreateForm() {
  const modals = useModals();
  const openForm = (id?: string) => {
    modals.drawer({
      id: 'template-create',
      title: `${id ? 'Editar' : 'Criar'} template`,
      size: 'lg',
      body: (
        <TemplateTaskCreateForm
          templateId={id}
          onClose={() => modals.close('template-create')}
        />
      ),
    });
  };
  return openForm;
}
