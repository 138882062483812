import { useReport } from './hooks/use-context';

export function Labels() {
  const { body } = useReport();

  return (
    <div
      style={{
        display: 'grid',
        fontSize: '10px',
        gridTemplateColumns: '5fr 8fr 5fr 12fr',
        breakInside: 'avoid',
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateRows: 'repeat(3, 1fr)',
        }}
      >
        <div
          style={{
            gridRow: '1 / span 3',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontWeight: 'bold',
            padding: '0px 5px',
          }}
        >
          Legenda:
        </div>
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateRows: 'repeat(3, 1fr)',
        }}
      >
        <div
          style={{
            display: 'flex',
            padding: '4px',
          }}
        >
          <strong>NC</strong> - Não Conforme
        </div>
        <div
          style={{
            display: 'flex',
            padding: '4px',
          }}
        >
          <strong>RE</strong> - Risco Específico
        </div>
        <div
          style={{
            display: 'flex',
            padding: '4px',
          }}
        >
          <strong>Peso</strong> - Peso itens ISSMA Geral
        </div>
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateRows: 'repeat(3, 1fr)',
        }}
      >
        <div
          style={{
            display: 'flex',
            padding: '4px',
          }}
        >
          <strong>IG</strong> - Não Conforme
        </div>
        <div
          style={{
            display: 'flex',
            padding: '4px',
          }}
        >
          <strong>ST</strong> - Risco Específico
        </div>
        <div
          style={{
            display: 'flex',
            padding: '4px',
          }}
        >
          <strong>GE</strong> - Grupo de Risco Específico
        </div>
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateRows: 'repeat(3, 1fr)',
        }}
      >
        <div
          style={{
            gridRow: '1 / span 3',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontWeight: 'bold',
            padding: '0px 5px',
          }}
        >
          Itens com asterisco (*) são para pagamento de premio operacional. No
          campo observações pertinente a cada item, junto com o desvio, caso
          seja evidenciado, deverá ser descrito o número do cadastro do
          colaborador. Deixará de ganhar resultado somente os colaboradores que
          estiverem o cadastro descrito na inspeção.
        </div>
      </div>
    </div>
  );
}
