/* eslint-disable no-restricted-syntax */
import { useFilter } from '@/hooks/Filters/filter';
import { OptionData } from '@hooks/Filters/interfaces';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { CHECK_ALL_KEY, checkAllOption } from './data';
import { MultiSelectionFilterProps } from './interfaces';
import Main from './Main';

const MultipleSelectionList: React.FC<MultiSelectionFilterProps> = ({
  column,
  type,
  table,
}) => {
  const [options, setOptions] = useState<OptionData[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<OptionData[]>(options);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const {
    getOptions,
    selectOption,
    clearOption,
    selectedOptions: initialValues,
  } = useFilter();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (initialValues[column] && initialValues[column][type]) {
      const initialValue = initialValues[column][type];
      if (initialValue) {
        const newSelected = initialValue.selected as string[];

        setSelectedOptions(newSelected);
      }
    } else {
      setSelectedOptions([]);
    }

    if (options.length - selectedOptions.length <= 1) setIsAllChecked(true);
    if (!selectedOptions.length) setIsAllChecked(false);
  }, [initialValues, column, type, selectedOptions.length, options.length]);

  const [search, setSearch] = useState('');

  useEffect(() => {
    async function fetchOptions() {
      const newOptions = await getOptions(column);
      setOptions(newOptions);
    }
    fetchOptions();
  }, [getOptions, column]);

  useEffect(() => {
    const filter =
      search.length > 0
        ? options.filter((item) => {
            return (
              item.label.toLowerCase().includes(search.toLowerCase()) ||
              item.id === CHECK_ALL_KEY
            );
          })
        : options;
    setFilteredOptions(filter);
  }, [options, search]);

  const onSearch = useCallback((search) => setSearch(search), []);

  const onAdd = useCallback(
    (selectedId: string) => {
      if (type === 'multi') {
        if (selectedId === CHECK_ALL_KEY) {
          const keys = filteredOptions
            .map((option) => option.id)
            .filter((key) => key !== CHECK_ALL_KEY);
          setSelectedOptions(keys);
          setIsAllChecked(true);
          selectOption({
            table,
            column,
            type: 'multi',
            selected: keys.filter((key) => key !== CHECK_ALL_KEY),
          });
        } else {
          selectOption({
            table,
            column,
            type: 'multi',
            selected: [
              ...selectedOptions.filter((key) => key !== CHECK_ALL_KEY),
              selectedId,
            ],
          });
          setSelectedOptions([...selectedOptions, selectedId]);

          if (options.length - selectedOptions.length <= 1)
            setIsAllChecked(true);
        }
      }
      if (type === 'single') {
        if (selectedId === selectedOptions[0]) {
          setSelectedOptions([]);
        } else {
          selectOption({ table, column, type: 'single', selected: selectedId });
          setSelectedOptions([selectedId]);
        }
      }
    },
    [
      type,
      filteredOptions,
      selectOption,
      table,
      column,
      selectedOptions,
      options,
    ],
  );

  const onRemove = useCallback(
    (selectedId) => {
      let newSelectedIds = selectedOptions.filter(
        (itemId) => itemId !== selectedId,
      );
      if (newSelectedIds.length === 0) {
        setIsAllChecked(false);
        clearOption({ column, type, table });
      } else {
        if (type === 'multi') {
          if (selectedId === CHECK_ALL_KEY) {
            setSelectedOptions([]);
            setIsAllChecked(false);
            newSelectedIds = [];
            clearOption({ column, type, table });
          } else {
            selectOption({
              table,
              column,
              type: 'multi',
              selected: newSelectedIds,
            });
          }
        }
        if (type === 'single') {
          selectOption({
            table,
            column,
            type: 'single',
            selected: newSelectedIds[0],
          });
        }
      }
      setSelectedOptions(newSelectedIds);
    },
    [column, selectOption, selectedOptions, type, clearOption, table],
  );

  const selected = useMemo(() => {
    const selected = options.filter((option) => {
      return selectedOptions.includes(option.id);
    });
    if (isAllChecked) {
      return [checkAllOption, ...selected];
    }
    return selected;
  }, [options, selectedOptions, isAllChecked]);

  const rest = useMemo(() => {
    const unselected = filteredOptions.filter(
      (option) => !selectedOptions.includes(option.id),
    );
    if (!isAllChecked) {
      return [checkAllOption, ...unselected];
    }
    return unselected;
  }, [selectedOptions, filteredOptions, isAllChecked]);

  return (
    <Main
      onAdd={onAdd}
      onRemove={onRemove}
      onSearch={onSearch}
      options={rest}
      selectedOptions={selected}
      type={type}
      isMobile={isMobile}
    />
  );
};

export default MultipleSelectionList;
