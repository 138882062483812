import { useReport } from './hooks/use-context';

export function LocationRef() {
  const { body } = useReport();

  const { location_ref } = body;

  return (
    <div style={{ marginBottom: '4px' }}>
      <div
        style={{
          width: '100%',
          backgroundColor: '#d3d3d3',
          textAlign: 'center',
          marginBottom: '4px'
        }}
      >
        REFERÊNCIA DE LOCALIZAÇÃO
      </div>
      <div
        style={{
          width: '100%',
          backgroundColor: '#ffffcc',
          textAlign: 'center',
        }}
      >
        {location_ref?.answers?.[0].content}
      </div>
    </div>
  );
}
