import { useReport } from './hooks/use-context';

export function Header({formName}: {formName: string}) {
  const { header } = useReport();


  if (!header) return null;

  return (
    <div
      style={{
        marginBottom: '1rem',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '2rem',
        color: 'black',
      }}
    >
      <div>
        <h1
          style={{
            fontSize: '1.5rem',
            fontWeight: 'bold',
            lineHeight: '1.25',
            color: '#000',
          }}
        >
          Formulário
        </h1>
        <p
          style={{
            fontSize: '1.2rem',
            lineHeight: '1.25',
            color: '#000',
          }}
        >
          {formName}
        </p>
      </div>
      <img
        style={{
          height: 'auto',
          width: '11rem',
        }}
        src={header?.logo}
        alt="logo"
      />
    </div>
  );
}
