/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
import { CollaboratorData } from './collaborator-data';
import { Header } from './header';
import { ReportContext } from './hooks/use-context';
import { InitialInfos } from './initial-infos';
import { LocationRef } from './location-ref';
import { OccurrenceDescription } from './occurrence-description';
import { Pictures } from './pictures';
import { Providences } from './providences';
import type {
  EventReportData,
  ParsedQuestions,
  QuestionsReporData,
} from './types';

export function GoiasaAlertReport(props: { reportData: EventReportData }) {
  const QUESTIONS_MAP: any = {
    '73dd0e94-1ecb-483b-9f75-1b33b2f32e47': 'probability',
    '7fb3f2d8-5cc2-4a44-9387-f03e10726d36': 'severity',
    'fd6d7623-7c5f-49ee-8309-e110d7fe9f99': 'impact_level',
    '2e3f76eb-7a79-419e-87ae-70dc79a1d900': 'event',
    '1187b12d-df88-4a31-9151-5fda721afa5d': 'recurrence',
    '2083a5ea-7795-4ab0-b199-2b597f34601a': 'date',
    '5652afeb-ffcc-48f8-a985-4799dc0854aa': 'shift',
    '0cfd7960-c608-4bc8-8f43-dda45dc513cb': 'classification',
    '21057992-1f18-452b-ae1f-88b34d52d189': 'area',

    '3d31557a-a927-48c6-bb1f-6fdbb3ff3846': 'location_ref',

    '1056777f-8bbd-4ca6-96fd-fd31c0f2f6ca': 'name',
    '0bb9e6df-d17d-43bd-8e73-96723de8193c': 'record_responsible',

    '61e627d2-40f4-4b2f-98c7-0d52d2d22dd2': 'cause',
    '352570f1-4fc7-4a9f-9344-9c0f3aaf9ce8': 'occurence_description',

    '352dc7f3-7bc5-4b35-b5df-4598075d97dd': 'providences',
    '230bc98c-01c1-49ea-b445-1c7a5649ffb2': 'providences_date_start',
    '982283d3-9aa4-4260-aa81-9e94b2c6b8fe': 'providences_date_end',
    'c70ae326-b4cf-49b9-9915-ad5b17a1ccbf': 'provider',

    'fc2c05ac-5f58-41a7-95bb-c14b811764cd': 'pictures',
  };

  const values = props?.reportData?.body?.reduce((acc, item) => {
    item.sections.map((section) =>
      section.questions.map((question) => {
        if (question?.question_id && QUESTIONS_MAP[question?.question_id])
          acc[QUESTIONS_MAP[question?.question_id]] = question;
      }),
    );
    return acc;
  }, [] as Array<any>);

  const parsedProps: QuestionsReporData = {
    header: props?.reportData?.header,
    body: (values as unknown) as ParsedQuestions,
  };
  return (
    <ReportContext value={parsedProps}>
      <div>
        <div
          style={{
            height: '90%',
            width: '100vw',
            flex: 1,
            backgroundColor: 'white',
            fontFamily: 'sans-serif',
          }}
        >
          <div
            style={{
              margin: '1rem auto',
              height: 'auto',
              maxWidth: '64rem',
            }}
          >
            <Header formName={props.reportData.body?.[0]?.form_name} />
            <div
              style={{
                height: '100%',
                width: '100%',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: '#000',
                backgroundColor: '#fff',
                padding: '1rem',
              }}
            >
              <InitialInfos />
              <LocationRef />
              <CollaboratorData />
              <OccurrenceDescription />
              <Providences />
              <Pictures />
            </div>
          </div>
        </div>
      </div>
    </ReportContext>
  );
}
