import _ from 'lodash';
import { useMemo } from 'react';

import { useReport } from './hooks/use-context';
import { AnswerData } from './types';

export function Pictures() {
  const { body } = useReport();

  const pictures = useMemo(() => {
    return body?.reduce((prev, curr) => {
      const nextState = _.orderBy(
        curr.sections.map((section) => section.questions).flat(),
        'position',
        'desc',
      )
        .filter((e) => ['gallery', 'picture'].includes(e.type))
        .map((q) => q.answers)
        .flat();
      const arr = [...prev, ...nextState];
      return arr;
    }, [] as Array<AnswerData>);
  }, [body]);

  return (
    <div style={{ breakInside: 'avoid' }}>
      <div
        style={{
          width: '100%',
          backgroundColor: '#d3d3d3',
          textAlign: 'center',
          padding: '0.5rem',
          fontWeight: 'bold',
          fontSize: '1.2rem',
        }}
      >
        REGISTRO FOTOGRÁFICO
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
          gap: '1rem',
          backgroundColor: '#ffffcc',
          padding: '1rem',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {pictures?.map((answer) => (
          <div
            key={answer.id}
            style={{
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                objectFit: 'contain',
                width: '100%',
                height: '100%',
                maxHeight: '300px',
              }}
              src={`${answer.content || ''}`}
              alt="img"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
